<template>
  <div class="uk-container uk-height-viewport uk-flex uk-flex-center">
    <div class="uk-margin-small-top" style="width: 400px; max-width: 100%">
      <div>
        <h1>
          <img
            :src="'/theme/logo'"
            width="60"
            alt="desys_logo"
          />

          <p class="uk-text-bold"><span>Log in</span></p>
        </h1>
      </div>

      <div class="uk-margin">
        <form
          :action="getQuery('tenantUrl') + '/servlet/auth'"
          name="login"
          @submit="submit"
        >
          <div class="uk-width-1-1 uk-margin" style="height: 60px">
            <div class="uk-width-1-1 uk-height-1-1 uk-inline-block">
              <input
                ref="userId"
                v-model="username"
                class="uk-input uk-height-1-1 uk-border-rounded"
                name="username"
                type="text"
                placeholder="Username"
                label="Username"
                autocomplete="username"
                data-test="loginUsernameField"
                :readonly="submitIsLoading"
              />
            </div>
          </div>

          <div class="uk-width-1-1 uk-margin" style="height: 60px">
            <div class="uk-width-1-1 uk-height-1-1 uk-inline-block">
              <input
                v-model="password"
                class="uk-input uk-height-1-1 uk-border-rounded"
                name="password"
                :type="showPassword ? 'text' : 'password'"
                placeholder="Password"
                label="Password"
                autocomplete="current-password"
                data-test="loginPasswordField"
                :readonly="submitIsLoading"
              />
              <a
                class="
                  bi
                  uk-form-icon
                  uk-form-icon-flip
                  uk-link-heading
                  uk-text-middle
                  uk-text-large
                "
                :class="showPassword ? 'bi-eye' : 'bi-eye-slash'"
                style="user-select: none"
                @click="showPassword = !showPassword"
              ></a>
            </div>
          </div>

          <input
            v-for="(value, key) in queryParams"
            :key="key"
            type="hidden"
            :name="key"
            :value="value"
          />

          <button
            type="submit"
            class="uk-width-1-1 uk-button uk-button-primary uk-border-pill"
            style="line-height: 46px"
            data-test="loginSubmitButton"
            :disabled="submitIsLoading"
          >
            <span class="uk-text-bold uk-text-normal" style="font-size: 1.3em"
              >Log in</span
            >
          </button>
        </form>
      </div>

      <div class="uk-width-1-1 uk-text-center">
        <a
          class="uk-link-heading"
          target="_blank"
          rel="noopener"
          href="https://extranet.desys.it/jsp/Template1/Privacy.jsp"
          >Privacy Policy</a
        >
        ·
        <a
          class="uk-link-heading"
          target="_blank"
          rel="noopener"
          href="https://extranet.desys.it/jsp/Template1/cookiesPolicy.jsp"
          >Cookie Policy</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import { nProgress } from "@rta/ui";

export default {
  data() {
    return {
      showPassword: false,
      username: "",
      password: "",
      loginError: null,
      submitIsLoading: false,
    };
  },
  computed: {
    queryParams() {
      const urlParams = new URLSearchParams(window.location.search);

      const queryParams = {};

      for (const [key, value] of urlParams) {
        queryParams[key] = value;
      }

      return queryParams;
    },
  },
  mounted() {
    this.$refs.userId.focus();
  },
  methods: {
    getQuery(key) {
      return this.queryParams[key];
    },
    submit() {
      this.submitIsLoading = true;
      nProgress.start();

      setTimeout(() => {
        window.location.reload();
      }, 10000);
    },
    /*
      async login() {
        try {
          const {
            data
          } = await this.$axios.$get(process.env.LOGIN_ENDPOINT, {
            params: {
              username: this.username,
              password: this.password,
              realm: process.env.REALM,
            },
          });

          this.$cookies.set("LinkersysAuth", data);
          this.$store.commit("setAuth", data);
          this.$router.push(this.localePath("/"));
        } catch (err) {
          // console.log(err);
          this.loginError = err.message;
        }
      },
    */
  },
};
</script>

<style>
svg .cls-1 {
  fill: #fff;
}

svg .cls-2 {
  fill: #f27023;
}
</style>
